<template>
  <!-- MODAL FIX SPOTS -->
  <b-modal
    id="fix-spots"
    ref="fix-spots"
    size="md"
    :title="$t('table.fixSpots')"
    :ok-disabled="$v.$invalid || isBusy"
    :ok-title="$t('table.fixSpots')"
    :cancel-title="$t('table.cancel')"
    @ok.prevent="fixSpots"
    @hidden="clearFields"
    @show="load"
  >
    <form v-if="currentMediaPlan" class="d-flex flex-wrap w-100 justify-content-between" @submit.stop.prevent="checkIfValidThenEnter">
      <input type="submit" value="Submit" class="hidden-submit" />
      <div class="d-flex gap-2 w-100">
        <b-form-group :label="$t('booking.every')" label-for="input-1-fix">
          <b-form-input id="input-1-fix" v-model.number="fixEvery" type="number" min="1" required style="width: 140px"></b-form-input>
        </b-form-group>
        <b-form-group class="w-75" :label="$t('booking.selectCommercial')">
          <MultiSelect
            v-model.trim="commercialFirst"
            label="name_with_id"
            track-by="id"
            :options="uniqueCommercialsList"
            :placeholder="$t('booking.selectCommercial')"
          ></MultiSelect>
        </b-form-group>
      </div>

      <b-form-group style="width: 49%" :label="$t('table.start_at')" label-for="datepicker-buttons-from">
        <datepicker-wrapper
          id="datepicker-buttons-from"
          v-model="dateFrom"
          required
          :min="currentMediaPlan.date_from"
          :max="currentMediaPlan.date_to"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </b-form-group>
      <b-form-group style="width: 49%" :label="$t('table.end_at')" label-for="datepicker-buttons-to">
        <datepicker-wrapper
          id="datepicker-buttons-to"
          v-model="dateTo"
          required
          :min="getMaxDate(dateFrom, currentMediaPlan.date_from)"
          :max="currentMediaPlan.date_to"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </b-form-group>

      <b-form-group :label="$t('table.start_at')" label-for="input-6" style="width: 49%">
        <BroadcastTimePicker id="input-6" v-model="timeFrom" :min-time="minTime" :max-time="maxTime" required :interval-mode="true" />
      </b-form-group>
      <b-form-group :label="$t('table.end_at')" label-for="input-7" style="width: 49%">
        <BroadcastTimePicker id="input-7" v-model="timeTo" :min-time="minTime" :max-time="maxTime" required :interval-mode="true" />
      </b-form-group>

      <b-form-group id="input-group-8" :label="$t('table.position')" style="width: 49%">
        <b-form-select v-model="selectedPosition" :options="premiumPositionOptions"></b-form-select>
      </b-form-group>
    </form>
  </b-modal>
  <!-- MODAL FIX SPOTS -->
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import MultiSelect from '@/components/MultiSelect';
import errorsHandler from '@/utils/errorsHandler';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import getMinOrMaxDate from '@/mixins/getMinOrMaxDate';
import setTimeOfSpot from '@/mixins/setTimeOfSpot';
import BroadcastTimePicker from '@/components/BroadcastTimePicker';

export default {
  name: 'ModalFixSpots',
  components: { MultiSelect, DatepickerWrapper, BroadcastTimePicker },
  mixins: [getMinOrMaxDate, setTimeOfSpot],
  props: {
    currentMediaPlan: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      fixEvery: 1,
      commercialFirst: '',
      dateFrom: '',
      dateTo: '',
      premiumPositionOptions: [
        { text: '', value: null },
        { text: '1F', value: '1F' },
        { text: '2F', value: '2F' },
        { text: '3F', value: '3F' },
        { text: '3L', value: '3L' },
        { text: '2L', value: '2L' },
        { text: '1L', value: '1L' },
      ],
      selectedPosition: '',
      uniqueCommercialsList: [],
      isBusy: false,
    };
  },
  validations: {
    fixEvery: { required, minValue: minValue(1) },
    commercialFirst: { required },
    dateFrom: { required },
    dateTo: { required },
    timeFrom: { required },
    timeTo: { required },
    selectedPosition: { required },
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
    }),
  },
  methods: {
    clearFields() {
      this.fixEvery = 1;
      this.commercialFirst = '';
      this.dateFrom = '';
      this.dateTo = '';
      this.timeFrom = this.minTime;
      this.timeTo = this.maxTime;
      this.selectedPosition = '';
      this.uniqueCommercialsList = [];
    },

    hideModalFixSpots() {
      this.$bvModal.hide('fix-spots');
    },

    async fixSpots() {
      this.isBusy = true;
      const formData = {
        nth: this.fixEvery,
        mediaplan_id: this.currentMediaPlan.id,
        commercial_id: this.commercialFirst.id,
        date_start_at: this.dateFrom,
        date_end_at: this.dateTo,
        start_interval: this.timeFrom,
        end_interval: this.timeTo,
        position: this.selectedPosition,
      };
      await this.$store.dispatch('POST_FIX_SPOTS', {
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.fixSpots'),
            text: this.$i18n.t('alert.found') + ': ' + res.data.data.count + '. ' + this.$i18n.t('alert.fixed') + ': ' + res.data.data.processed,
          });
          this.hideModalFixSpots();
          this.$emit('updateData');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },
    checkIfValidThenEnter() {
      if (!this.$v.$invalid) this.fixSpots();
    },

    loadDates() {
      this.dateFrom = this.currentMediaPlan.date_from;
      this.dateTo = this.currentMediaPlan.date_to;
      this.uniqueCommercialsList = [];
      this.uniqueCommercialsList = [
        ...new Map(
          this.currentMediaPlan.commercials.map(function (item) {
            item.name_with_id = `[${item['id']}] ${item['name']}`;

            return [item['id'], item];
          })
        ).values(),
      ];
    },
  },
};
</script>
